<template>
    <div class="incubator page normal-page">
        <div class="page-banner">
            <img
                class="desktop-banner"
                :src="image(content.imageDes)"
                alt=""
            >
            <img
                class="mobile-banner"
                :src="image(content.imageMob)"
                alt=""
            >
        </div>
        <div class="web-content">
            <div class="page-structure">
                <PageMenu :menu-data="pageMenu" />
                <div class="page-content column-center">
                    <Anchor :id="'explain'" />
                    <ExplainBlock
                        :content="content"
                    />
                    <Anchor :id="'registration'" />
                    <RegistrationBlock
                        :content="content"
                    />
                    <Anchor :id="'q-and-a'" />
                    <QandABlock
                        :question="qa"
                    />
                    <Anchor :id="'recommend'" />
                    <RecommendBlock
                        :recommend="recommend"
                    />
                    <Anchor :id="'history-teacher'" />
                    <TeacherList
                        :teacher="teacher"
                    />
                    <Anchor :id="'history-team'" />
                    <HistoryBlock
                        :history-team="team"
                    />
                    <div
                        class="sign-up-btn"
                        @click="goToSignUp"
                    >
                        {{ $t('incubator.goToSignUp') }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import API from '@/api';
import { mapMutations } from 'vuex';
import ExplainBlock from '@/components/Incubator/ExplainBlock.vue';
import RegistrationBlock from '@/components/Incubator/RegistrationBlock.vue';
import QandABlock from '@/components/Incubator/QandABlock.vue';
import RecommendBlock from '@/components/Incubator/RecommendBlock.vue';
import HistoryBlock from '@/components/Incubator/HistoryBlock.vue';
import TeacherList from '@/components/Incubator/TeacherList.vue';
import Nzh from 'nzh';
import PageMenu from '../components/PageMenu.vue';

export default {
    components: {
        PageMenu,
        ExplainBlock,
        RegistrationBlock,
        QandABlock,
        RecommendBlock,
        HistoryBlock,
        TeacherList,
    },
    data() {
        return {
            content: {},
            qa: null,
            recommend: null,
            teacher: null,
            team: null,
            pageMenu: {
                // eslint-disable-next-line global-require
                icon: require('../assets/incubator/menu-icon.png'),
                title: 'incubator.pageMenu.title',
                button: {
                    text: 'incubator.pageMenu.button',
                    routerTo: '/incubator-apply/team-data/new',
                },
                items: [
                    {
                        type: 'link',
                        text: 'incubator.pageMenu.items.item1.text',
                        link: '#explain',
                    },
                    {
                        type: 'panel',
                        text: 'incubator.pageMenu.items.item2.text',
                        blockItems: [
                            {
                                type: 'link',
                                text: 'incubator.pageMenu.items.item2.blockItems[0]',
                                target: '_blank',
                                link: 'https://www.surveycake.com/s/Yg2My',
                            },
                            {
                                type: 'link',
                                text: 'incubator.pageMenu.items.item2.blockItems[1]',
                                link: '#qualification',
                            },
                            {
                                type: 'link',
                                text: 'incubator.pageMenu.items.item2.blockItems[2]',
                                link: '#apply-method',
                            },
                            {
                                type: 'link',
                                text: 'incubator.pageMenu.items.item2.blockItems[3]',
                                link: '#judge-method',
                            },
                            {
                                type: 'link',
                                text: 'incubator.pageMenu.items.item2.blockItems[4]',
                                link: '#schedule',
                            },
                            {
                                type: 'link',
                                text: 'incubator.pageMenu.items.item2.blockItems[5]',
                                link: '#grants',
                            },
                            {
                                type: 'link',
                                text: 'incubator.pageMenu.items.item2.blockItems[6]',
                                link: '#theme',
                            },
                            {
                                type: 'link',
                                text: 'incubator.pageMenu.items.item2.blockItems[7]',
                                link: '#contact-us',
                            },
                            {
                                type: 'link',
                                text: 'incubator.pageMenu.items.item2.blockItems[8]',
                                link: '#rights',
                            },
                        ],
                    },
                    {
                        type: 'link',
                        text: 'incubator.pageMenu.items.item3.text',
                        link: '#q-and-a',
                    },
                    {
                        type: 'link',
                        text: 'incubator.pageMenu.items.item4.text',
                        link: '#recommend',
                    },
                    {
                        type: 'link',
                        text: 'incubator.pageMenu.items.item5.text',
                        link: '#history-teacher',
                    },
                    {
                        type: 'link',
                        text: 'incubator.pageMenu.items.item6.text',
                        link: '#history-team',
                    },
                ],
            },
        };
    },
    computed: {},
    watch: {
        $route() {
            this.init();
        },
    },
    created() {},
    mounted() {
        this.init();
    },
    methods: {
        ...mapMutations('mNotification', ['setAlert', 'handleError']),
        async init() {
            try {
                const res = await API.IncubatorContent.PublicList({
                    headers: {
                        'Content-Language': (this.$route.path.split('/')[1] || 'zhTw') === 'en' ? 'en' : 'zhTw',
                    },
                });
                // eslint-disable-next-line prefer-destructuring
                this.content = res.data.raw[0];
            } catch (e) {
                this.handleError({
                    message: e || this.$t('alertMessage.getdata-fail'),
                    color: 'error',
                });
            }
            try {
                const res = await API.IncubatorQA.PublicList({
                    headers: {
                        'Content-Language': (this.$route.path.split('/')[1] || 'zhTw') === 'en' ? 'en' : 'zhTw',
                    },
                });
                this.qa = res.data.raw;
            } catch (e) {
                this.handleError({
                    message: e || this.$t('alertMessage.getdata-fail'),
                    color: 'error',
                });
            }
            try {
                const res = await API.IncubatorRecommend.PublicList({
                    headers: {
                        'Content-Language': (this.$route.path.split('/')[1] || 'zhTw') === 'en' ? 'en' : 'zhTw',
                    },
                });
                this.recommend = res.data.raw;
            } catch (e) {
                this.handleError({
                    message: e || this.$t('alertMessage.getdata-fail'),
                    color: 'error',
                });
            }
            try {
                const res = await API.IncubatorTeacher.PublicList({
                    headers: {
                        'Content-Language': (this.$route.path.split('/')[1] || 'zhTw') === 'en' ? 'en' : 'zhTw',
                    },
                });
                this.teacher = res.data.raw;
            } catch (e) {
                this.handleError({
                    message: e || this.$t('alertMessage.getdata-fail'),
                    color: 'error',
                });
            }
            try {
                const historyTeam = [];
                const res = await API.IncubatorTeam.PublicList({
                    headers: {
                        'Content-Language': (this.$route.path.split('/')[1] || 'zhTw') === 'en' ? 'en' : 'zhTw',
                    },
                });
                const session = [];
                for (let i = 0; i < res.data.raw.length; i += 1) {
                    if (!session.includes(res.data.raw[i].session)) {
                        session.push(res.data.raw[i].session);
                        session.sort((a, b) => a - b);
                    }
                }
                for (let i = 0; i < session.length; i += 1) {
                    const nzhcn = Nzh.hk;
                    let number = `${session[i]} Session`;
                    if (this.$route.path.split('/')[1] === 'zhTw') {
                        number = `第${nzhcn.encodeS(session[i])}屆`;
                    }
                    historyTeam.push({
                        number: session[i],
                        title: number,
                        items: [
                            res.data.raw.filter(
                                (value) => value.session === session[i],
                            ),
                        ],
                    });
                    historyTeam.sort(this.asort_json_num('number'));
                    this.team = historyTeam;
                }
            } catch (e) {
                this.handleError({
                    message: e || this.$t('alertMessage.getdata-fail'),
                    color: 'error',
                });
            }
        },
        goToSignUp() {
            const language = this.$route.path.split('/')[1];
            if (language === 'zhTw' || language === 'en') {
                this.$router.push(`/${language}/incubator-apply/team-data/new`);
            } else {
                this.$router.push('/zhTw/incubator-apply/team-data/new');
            }
        },
        asort_json_num(property) {
            return (a, b) => b[property] - a[property];
        },
        image(item) {
            const file = `https://${process.env.VUE_APP_BASE_URL + item}`;
            return file;
        },
    },
};
</script>

<style lang="scss">
.incubator{
    .page-banner{
     width: 100vw;
     height: 300/1440*100vw;
     overflow: hidden;
     img{
      width: 100vw;
      object-fit: contain;
     }
    }

  .block-content {
    padding-left: 10px;
    font-weight: 700;
    font-size: 16px;
    line-height: 2;
    text-align: justify;
    letter-spacing: 0.1em;
    color: $color-gray;
  }

  .sign-up-btn {
    font-weight: 700;
    font-size: 28px;
    line-height: 1;
    letter-spacing: 0.3em;
    color: white;
    background-color: $color-orange;
    border-radius: 5px;
    width: 75%;
    padding: 20px;
    text-align: center;
    margin-bottom: 80px;
    cursor: pointer;
  }
}

@media screen and (max-width: 960px) {
  .incubator {
    .sign-up-btn {
      width: 100%;
    }
  }
}

@media screen and (max-width: 600px) {
  .incubator {
    .sign-up-btn {
      font-size: 18px;

    }
  }
}
</style>
