<!-- eslint-disable vue/no-v-html -->
<template>
    <div class="explain-block block">
        <div class="block-title">
            <div class="dot" />
            {{ $t('incubator.explain.title') }}
        </div>
        <div class="video">
            <iframe
                class="video-frame"
                :src="getVideo(content.video)"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write;
            encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
            />
        </div>
        <div class="content sub-block">
            <div
                class="block-content"
                v-html="content.explain"
            />
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        content: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {};
    },
    computed: {},
    created() { },
    mounted() { },
    methods: {
        getVideo(value) {
            if (typeof value !== 'undefined' && value.includes('watch?v=')) {
                const id = value.split('watch?v=')[1].split('&')[0];
                return `https://www.youtube.com/embed/${id}`;
            }
            return value;
        },
    },
};
</script>

<style lang="scss" scoped>
.explain-block {
    .video {
        position: relative;
        padding-bottom: 56.36%; /* 16:9 */
        width: 100%;
        height: 0;
        margin-bottom: 50px;

        .video-frame {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    .content {
        .block-content {
            .top {
              font-size: 21px;
              color: $color-blue;
            }
        }
    }
}
</style>
