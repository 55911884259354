<!-- eslint-disable vue/no-v-html -->
<template>
    <div class="registration-block block">
        <div class="block-title">
            <div class="dot" />
            {{ $t('incubator.registration.title[0]') }}
        </div>
        <Anchor :id="'qualification'" />
        <div class="sub-block">
            <div class="sub-title">
                {{ $t('incubator.registration.title[1]') }}
            </div>
            <div
                class="block-content"
                v-html="content.qualifications"
            />
        </div>
        <Anchor :id="'apply-method'" />
        <div class="sub-block">
            <div class="sub-title">
                {{ $t('incubator.registration.title[2]') }}
            </div>
            <div
                class="block-content"
                v-html="content.applyMethod"
            />
        </div>
        <Anchor :id="'judge-method'" />
        <div class="sub-block">
            <div class="sub-title">
                {{ $t('incubator.registration.title[3]') }}
            </div>
            <div
                class="block-content"
                v-html="content.judgeMethod"
            />
        </div>
        <Anchor :id="'schedule'" />
        <div class="sub-block">
            <div class="sub-title">
                {{ $t('incubator.registration.title[4]') }}
            </div>
            <div
                class="block-content"
                v-html="content.schedule"
            />
        </div>
        <Anchor :id="'grants'" />
        <div class="sub-block">
            <div class="sub-title">
                {{ $t('incubator.registration.title[5]') }}
            </div>
            <div
                class="block-content"
                v-html="content.grants"
            />
        </div>
        <Anchor :id="'theme'" />
        <div class="sub-block">
            <div class="sub-title">
                {{ $t('incubator.registration.title[6]') }}
            </div>
            <div
                class="block-content"
                v-html="content.theme"
            />
        </div>
        <Anchor :id="'contact-us'" />
        <div class="sub-block contact">
            <div class="sub-title">
                {{ $t('incubator.registration.title[7]') }}
            </div>
            <div
                class="block-content"
                v-html="content.contact"
            />
        </div>
        <Anchor :id="'rights'" />
        <div class="sub-block">
            <div class="sub-title">
                {{ $t('incubator.registration.title[8]') }}
            </div>
            <div
                class="block-content"
                v-html="content.rights"
            />
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        content: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
        };
    },
    computed: {},
    created() { },
    mounted() { },
    methods: {},
};
</script>

<style lang="scss">
.registration-block {
  .apply-methods-img{
    width: 100%;
    margin-top: 10px;
  }
}
</style>
