<!-- eslint-disable vue/no-v-html -->
<template>
    <div class="team-dialog">
        <div
            v-if="dialogData"
            class="dialog-body"
        >
            <v-icon
                class="close-btn"
                @click="close"
            >
                mdi-close
            </v-icon>
            <div class="dialog-content">
                <div class="top">
                    <div
                        v-show="dialogData.rank"
                        class="rank"
                    >
                        {{ dialogData.rank }}
                    </div>
                    <div class="team-title">
                        {{ dialogData.title }}
                    </div>
                    <div class="team-name">
                        {{ dialogData.name }}
                    </div>
                    <div class="image">
                        <img
                            :src="image(dialogData.image)"
                            alt=""
                        >
                    </div>
                </div>
                <div class="content">
                    <div
                        v-if="dialogData.selfTheme"
                        class="section"
                    >
                        <div class="section-title">
                            {{ $t('incubator.teamDialog.title[0]') }}
                        </div>
                        <div
                            class="section-text"
                            v-html="textHandle(dialogData.selfTheme)"
                        />
                    </div>
                    <div class="section">
                        <div class="section-title">
                            {{ $t('incubator.teamDialog.title[1]') }}
                        </div>
                        <div
                            class="section-text"
                            v-html="textHandle(dialogData.shareTheme)"
                        />
                    </div>
                    <div class="section">
                        <div class="section-title">
                            {{ $t('incubator.teamDialog.title[2]') }}
                        </div>
                        <div
                            class="section-text"
                            v-html="textHandle(dialogData.content)"
                        />
                    </div>
                </div>
                <div
                    v-show="
                        dialogData.fb ||
                            dialogData.ig ||
                            dialogData.youtube ||
                            dialogData.mail
                    "
                    class="contact"
                >
                    <div class="contact-title">
                        {{ $t('incubator.teamDialog.title[3]') }}
                    </div>
                    <div class="sns-list">
                        <a
                            v-show="dialogData.fb"
                            class="sns"
                            :href="dialogData.fb"
                            target="_blank"
                        ><img
                            class="image"
                            src="../../assets/sns/fb.png"
                            alt=""
                        ></a>
                        <a
                            v-show="dialogData.ig"
                            class="sns"
                            :href="dialogData.ig"
                            target="_blank"
                        ><img
                            class="image"
                            src="../../assets/sns/ig.png"
                            alt=""
                        ></a>
                        <a
                            v-show="dialogData.youtube"
                            class="sns"
                            :href="dialogData.youtube"
                            target="_blank"
                        ><img
                            class="image"
                            src="../../assets/sns/YT.png"
                            alt=""
                        ></a>
                        <a
                            v-show="dialogData.mail"
                            class="sns"
                            :href="mailHref(dialogData.mail)"
                            target="_blank"
                        ><img
                            class="image"
                            src="../../assets/sns/mail.png"
                            alt=""
                        ></a>
                    </div>
                </div>
                <div class="bottom">
                    <div
                        class="return-btn"
                        @click="close"
                    >
                        {{ $t('incubator.teamDialog.button') }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    components: {},
    props: {
        dialogData: {
            type: Object,
            default() {
                return {
                    rank: '',
                    image: '',
                    title: '',
                    name: '',
                    selfTheme: '',
                    shareTheme: '',
                    content: '',
                    fb: '',
                    ig: '',
                    youtube: '',
                    mail: '',
                };
            },
        },
    },
    data() {
        return {};
    },
    computed: {},
    created() { },
    mounted() { },
    methods: {
        mailHref(mail) {
            return `mailto:${mail}`;
        },
        close() {
            this.$emit('close');
        },
        textHandle(text) {
            if (text) {
                return text.replace(/(\r\n)|(\n)/g, '<br>');
            }
            return 'text';
        },
        image(item) {
            const file = `https://${process.env.VUE_APP_BASE_URL + item}`;
            return file;
        },
    },
};
</script>

<style lang="scss" scoped>
.team-dialog {
  width: 100vw;
  min-height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  .dialog-body {
    width: 80vw;
    height: 80vh;
    max-width: 1066px;
    position: relative;
    background-color: white;
    padding: 85px 90px 25px 90px;
  }

  .dialog-content {
    height: 100%;
    overflow: scroll;
  }

  .close-btn {
    font-size: 25px;
    position: absolute;
    top: 27px;
    right: 27px;
    color: $color-orange;
    cursor: pointer;
  }

  .top {
    display: flex;
    flex-direction: column;
    align-items: center;

    .rank {
      font-weight: 700;
      font-size: 16px;
      line-height: 1;
      letter-spacing: 0.1em;
      color: white;
      background-color: $color-orange;
      padding: 5px 8px;
      margin-bottom: 15px;
    }
    .team-title {
      font-weight: 700;
      font-size: 28px;
      line-height: 1;
      text-align: center;
      letter-spacing: 0.1em;
      color: $color-blue;
      margin-bottom: 15px;
      word-break: break-word;
    }

    .team-name {
      font-weight: 700;
      font-size: 21px;
      line-height: 1;
      text-align: center;
      letter-spacing: 0.1em;
      color: $color-blue;
      margin-bottom: 28px;
    }

    .image {
      img {
        width: 500px;
        height: 500px;
      }
      margin-bottom: 30px;
    }
  }

  .section {
    margin-bottom: 46px;
    .section-title {
      font-weight: 700;
      font-size: 21px;
      letter-spacing: 0.1em;
      color: $color-blue;
      padding-left: 10px;
      border-left: 4px solid $color-orange;
      margin-bottom: 30px;
    }

    .section-text {
      padding-left: 14px;
      font-weight: 700;
      font-size: 16px;
      line-height: 2;
      letter-spacing: 0.1em;
      color: $color-gray;
    }
  }

  .contact {
    display: flex;
    align-items: center;
    margin-bottom: 50px;

    .contact-title {
      font-weight: 700;
      font-size: 21px;
      line-height: 1;
      letter-spacing: 0.1em;
      color: $color-blue;
      padding-left: 10px;
      border-left: 4px solid $color-orange;
      margin-bottom: 5px;
    }
    .sns-list {
      margin-left: 20px;
      .sns {
        img {
          width: 42px;
          height: 42px;
          margin-right: 13px;
        }
      }
    }
  }

  .bottom {
    display: flex;
    justify-content: center;
    .return-btn {
      background-color: $color-orange;
      border-radius: 4px;
      font-weight: 700;
      font-size: 16px;
      line-height: 16px;
      letter-spacing: 0.2em;
      color: #ffffff;
      padding: 18px 40px;
      text-align: center;
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 960px) {
  .team-dialog {
    .dialog-body {
      padding: 85px 60px 25px 60px;
    }
    .top {
      .image {
        img {
          width: 400px;
          height: 400px;
        }
        margin-bottom: 20px;
      }
    }
  }
}

@media screen and (max-width: 760px) {
  .team-dialog {
    .dialog-body {
      padding: 85px 40px 25px 40px;
    }
    .top {
      .image {
        img {
          width: 350px;
          height: 350px;
        }
        margin-bottom: 25px;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .team-dialog {
    .dialog-body {
      width: 100vw;
      height: 100vh;
      padding: 85px 20px 25px 20px;
    }
    .top {
      .rank {
        font-size: 16px;
        margin-bottom: 14px;
      }
      .team-title {
        font-size: 21px;
        margin-bottom: 8px;
      }

      .team-name {
        font-size: 16px;
        margin-bottom: 18px;
      }
      .image {
        img {
          width: 300px;
          height: 300px;
        }
      }
    }

    .section {
      margin-bottom: 36px;
      .section-title {
        font-weight: 700;
        font-size: 16px;
        padding-left: 8px;
      }

      .section-text {
        padding-left: 5px;
        font-size: 14px;
      }
    }

    .contact {
      margin-bottom: 30px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      .contact-title {
        font-size: 16px;
        padding-left: 8px;
        margin-bottom: 5px;
        line-height: 1;
        width: 100%;
        margin-bottom: 18px;
      }
      .sns-list {
        margin-left: 20px;
        width: 100%;
        .sns {
          img {
            width: 42px;
            height: 42px;
            margin-right: 13px;
          }
        }
      }
    }
    .bottom {
      .return-btn {
        width: 100%;
        font-size: 16px;
        line-height: 16px;
        text-align: center;
      }
    }
  }
}
</style>
