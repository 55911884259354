<template>
    <div class="q-and-a block">
        <div class="block-title">
            <div class="dot" />
            {{ $t('incubator.QandA.title') }}
        </div>
        <div class="sub-block">
            <div class="panels">
                <v-expansion-panels
                    multiple
                    accordion
                >
                    <v-expansion-panel
                        v-for="(item, i) in tmpQuestion"
                        :key="i"
                        class="panel-item"
                    >
                        <v-expansion-panel-header class="question">
                            {{ item.title }}
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="answer">
                            {{ item.content }}
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </div>
            <div
                v-if="!more"
                class="more"
                @click="handleMore"
            >
                <div class="text color-orange">
                    {{ $t('incubator.QandA.more') }}
                </div>
                <v-icon class="icon">
                    mdi-chevron-down
                </v-icon>
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash';

export default {
    components: {},
    props: {
        question: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            more: false,
        };
    },
    computed: {
        tmpQuestion() {
            if (this.more) {
                return this.question;
            }
            return _.take(this.question, 10);
        },
    },
    created() {
        this.init();
    },
    mounted() {
    },
    methods: {
        init() {
            if (typeof this.question !== 'object' && this.question.length <= 10) {
                this.more = true;
            }
        },
        handleMore() {
            this.more = true;
        },
    },
};
</script>

<style lang="scss">
.q-and-a {
    .question {
        font-weight: 700;
        font-size: 21px;
        line-height: 2;
        letter-spacing: 0.1em;
        color: $color-blue;
    }
    .answer {
        font-weight: 700;
        font-size: 16px;
        line-height: 2;
        letter-spacing: 0.1em;
        color: $color-gray;
    }

    .more {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 25px;
        cursor: pointer;
        font-weight: 700;
        font-size: 21px;
        line-height: 1;
        letter-spacing: 0.1em;
        color: $color-orange;

        .icon {
            color: $color-orange;
            font-size: 32px;
            margin-top: 4px;
        }
    }

    .v-expansion-panel-header__icon {
        position: absolute;
        right: 0;
        .v-icon {
            color: $color-orange !important;
        }
    }
}

@media screen and (max-width: 600px) {
    .q-and-a {
        .question {
            font-size: 16px;
        }
        .answer {
            font-size: 14px;
        }

        .more {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 25px;
            font-size: 18px;
            cursor: pointer;

            .icon {
                color: $color-orange;
                font-size: 26px;
            }
        }
    }
}
</style>
